<template>
  <div id="demo" class="wh100 relative text-center">
    <div id="heritage" class="absolute"></div>
    <div id="repository" class="absolute"></div>
    <img class="img-rama" src="../assets/frame.jpg" alt="pic" />
  </div>
</template>

<script>
  //import SVGTextAnimate from  '../assets/js/svg-text-animate';
  import Vivus from 'vivus';
  export default {
    name: 'Home',
    mounted () {
      const clbk = () => {
        console.log('done');
      };
      setTimeout(() => {
        const duration = 160;
        new Vivus(
          'heritage',
          { duration, file: require('../assets/heritage.svg') },
          clbk
        );
        setTimeout(() => {
          new Vivus(
            'repository',
            { duration, file: require('../assets/repository.svg') },
            clbk
          );
        }, 1000);
      }, 1000);
      // const text1 = new SVGTextAnimate(require('./Caveat-VariableFont_wght.ttf'), {
      //   "duration": 300,
      //   "direction": "alternate",
      //   "fill-mode": "forwards",
      //   "delay": 300,
      //   "mode": "onebyone",
      //   "color": "white",
      // }, {
      //   "fill": "white",
      //   "stroke": "white",
      //   "stroke-width": "5px",
      //   "font-size": 170
      // });
      // const text2 = new SVGTextAnimate(require('./Caveat-VariableFont_wght.ttf'), {
      //   "duration": 300,
      //   "direction": "alternate",
      //   "fill-mode": "forwards",
      //   "delay": 300,
      //   "mode": "onebyone",
      //   "color": "white",
      // }, {
      //   "fill": "white",
      //   "stroke": "white",
      //   "stroke-width": "5px",
      //   "font-size": 170,
      // });
      // setTimeout(function(){
      //   text2.create("repository", "#text2");
      // }, 3000);
      // await text1.setFont();
      // await text2.setFont();
      // text1.create("heritage", "#text1");
    }
  };
</script>

<style lang="scss">
  #demo {
    figure {
      align-self: center;
      img {
        height: 400px;
      }
    }
    #text1 {
      position: absolute;
      z-index: 1;
      left: 453px;
      top: 200px;
    }
    #text2 {
      animation-delay: 1.5s;
      position: absolute;
      z-index: 1;
      left: 405px;
      top: 380px;
    }
    .img-rama {
      width: 1000px;
      position: absolute;
      left: 200px;
      right: 200px;
    }
    div.absolute {
      width: 500px;
      z-index: 99;
      opacity: 0.9;
    }
    #heritage {
      top: 150px;
      left: 400px;
    }
    #repository {
      top: 370px;
      left: 500px;
    }
  }
</style>
